import Guard from "../services/middleware"

const bankAccountRoutes = [
  {
    path: "/bankaccount",
    name: "bankaccount",
    beforeEnter: Guard.auth,
    components: {
      default: () => import("../views/BankAccount/BankAccount"),
      menu: () => import("../views/Menu"),
    },
  },
  {
    path: "/bankaccount/add",
    name: "bankaccountadd",
    beforeEnter: Guard.auth,
    components: {
      default: () => import("../views/BankAccount/BankAccountAdd"),
      menu: () => import("../views/Menu"),
    },
  },
  {
    path: "/bankaccount/edit/:id?",
    name: "bankaccountedit",
    beforeEnter: Guard.auth,
    components: {
      default: () => import("../views/BankAccount/BankAccountEdit"),
      menu: () => import("../views/Menu"),
    },
  },
]

export default bankAccountRoutes
