import Guard from '../services/middleware'

const prodServRoutes = [
    {
        path: '/prodserv',
        name: 'prodserv',
        beforeEnter: Guard.auth,
        components: {
            default: () => import('../views/ProdServ/ProdServ'),
            menu: () => import('../views/Menu'),
        }
    },
    {
        path: '/prodserv/add',
        name: 'prodservadd',
        beforeEnter: Guard.auth,
        components: {
            default: () => import('../views/ProdServ/ProdServAdd'),
            menu: () => import('../views/Menu'),
        }
    },
    {
        path: '/prodserv/edit/:id?',
        name: 'prodservedit',
        beforeEnter: Guard.auth,
        components: {
            default: () => import('../views/ProdServ/ProdServEdit'),
            menu: () => import('../views/Menu'),
        }
    },
]

export default prodServRoutes