import Guard from "../services/middleware"

const nasRoutes = [
  {
    path: "/nas",
    name: "nas",
    beforeEnter: Guard.auth,
    components: {
      default: () => import("../views/Nas/Nas"),
      menu: () => import("../views/Menu"),
    },
  },
  {
    path: "/nas/add",
    name: "nasadd",
    beforeEnter: Guard.auth,
    components: {
      default: () => import("../views/Nas/NasAdd"),
      menu: () => import("../views/Menu"),
    },
  },
  {
    path: "/nas/edit/:id?",
    name: "nasedit",
    beforeEnter: Guard.auth,
    components: {
      default: () => import("../views/Nas/NasEdit"),
      menu: () => import("../views/Menu"),
    },
  },
]

export default nasRoutes
