import Guard from "../services/middleware"

const cashHandRoutes = [
  {
    path: "/cashhand",
    name: "cashhand",
    beforeEnter: Guard.auth,
    components: {
      default: () => import("../views/CashHand/CashHand"),
      menu: () => import("../views/Menu"),
    },
  },
  {
    path: "/cashhand/add",
    name: "cashhandadd",
    beforeEnter: Guard.auth,
    components: {
      default: () => import("../views/CashHand/CashHandAdd"),
      menu: () => import("../views/Menu"),
    },
  },
  {
    path: "/cashhand/edit/:id?",
    name: "cashhandedit",
    beforeEnter: Guard.auth,
    components: {
      default: () => import("../views/CashHand/CashHandEdit"),
      menu: () => import("../views/Menu"),
    },
  },
]

export default cashHandRoutes
