import Guard from '../services/middleware'

const receivableRoutes = [
  {
    path: '/customer/receivable',
    name: 'receivable',
    beforeEnter: Guard.auth,
    components: {
      default: () => import('../views/Receivable/Receivable'),
      menu: () => import('../views/Menu'),
    }
  },
  {
    path: '/customer/receivable/add',
    name: 'receivableadd',
    beforeEnter: Guard.auth,
    components: {
      default: () => import('../views/Receivable/ReceivableAdd'),
      menu: () => import('../views/Menu'),
    }
  },
  {
    path: '/customer/receivable/settling',
    name: 'receivablesettling',
    beforeEnter: Guard.auth,
    components: {
      default: () => import('../views/Receivable/ReceivableSettling'),
      menu: () => import('../views/Menu'),
    }
  },
  {
    path: '/customer/promisepayment',
    name: 'promisepayment',
    beforeEnter: Guard.auth,
    components: {
      default: () => import('../views/PromisePayment/PromisePayment'),
      menu: () => import('../views/Menu'),
    }
  },
  {
    path: '/customer/promisepaymentadd',
    name: 'promisepaymentadd',
    beforeEnter: Guard.auth,
    components: {
      default: () => import('../views/PromisePayment/PromisePaymentAdd'),
      menu: () => import('../views/Menu'),
    }
  }
]

export default receivableRoutes