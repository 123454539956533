import Guard from "../services/middleware"

const boxRoutes = [
  {
    path: "/box",
    name: "box",
    beforeEnter: Guard.auth,
    components: {
      default: () => import("../views/Box/Box"),
      menu: () => import("../views/Menu"),
    },
  },
  {
    path: "/box/boxadd",
    name: "boxadd",
    beforeEnter: Guard.auth,
    components: {
      default: () => import("../views/Box/BoxAdd"),
      menu: () => import("../views/Menu"),
    },
  },
  {
    path: "/box/boxedit/:id?",
    name: "boxedit",
    beforeEnter: Guard.auth,
    components: {
      default: () => import("../views/Box/BoxEdit"),
      menu: () => import("../views/Menu"),
    },
  },
]

export default boxRoutes
