import Guard from '../services/middleware'

const customerRoutes = [
  {
    path: '/customer',
    name: 'customer',
    beforeEnter: Guard.auth,
    components: {
      default: () => import('../views/Customer/Customer'),
      menu: () => import('../views/Menu'),
    }
  },
  {
    path: '/customer/add',
    name: 'customeradd',
    beforeEnter: Guard.auth,
    components: {
      default: () => import('../views/Customer/CustomerAdd'),
      menu: () => import('../views/Menu'),
    }
  },
  {
    path: '/customer/edit',
    name: 'customeredit',
    beforeEnter: Guard.auth,
    components: {
      default: () => import('../views/Customer/CustomerEdit'),
      menu: () => import('../views/Menu'),
    }
  },
  {
    path: '/customer/contact/add',
    name: 'contactadd',
    beforeEnter: Guard.auth,
    components: {
      default: () => import('../views/Customer/Contact/ContactAdd'),
      menu: () => import('../views/Menu'),
    }
  },
  {
    path: '/customer/contact/edit/:id?',
    name: 'contactedit',
    beforeEnter: Guard.auth,
    components: {
      default: () => import('../views/Customer/Contact/ContactEdit'),
      menu: () => import('../views/Menu'),
    }
  },
]

export default customerRoutes