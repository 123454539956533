import Guard from '../services/middleware'

const reportRoutes = [
    {
        path: '/report/workorder',
        name: 'workorderreport',
        beforeEnter: Guard.auth,
        components: {
            default: () => import('../views/Report/WorkOrderReport'),
            menu: () => import('../views/Menu'),
        }
    },
    {
        path: '/report/contract',
        name: 'contractreport',
        beforeEnter: Guard.auth,
        components: {
            default: () => import('../views/Report/ContractReport'),
            menu: () => import('../views/Menu'),
        }
    },
    {
        path: '/report/receivable',
        name: 'receivablereport',
        beforeEnter: Guard.auth,
        components: {
            default: () => import('../views/Report/ReceivableReport'),
            menu: () => import('../views/Menu'),
        }
    },
    {
        path: '/report/technicianlocation',
        name: 'technicianlocationreport',
        beforeEnter: Guard.auth,
        components: {
            default: () => import('../views/Report/TechnicianLocationReport'),
            menu: () => import('../views/Menu'),
        }
    },
]

export default reportRoutes