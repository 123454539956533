import Guard from '../services/middleware'

const contractTypeRoutes = [
  {
    path: '/contracttype',
    name: 'contracttype',
    beforeEnter: Guard.auth,
    components: {
      default: () => import('../views/ContractType/ContractType'),
      menu: () => import('../views/Menu'),
    }
  },
  {
    path: '/contracttype/add',
    name: 'contracttypeadd',
    beforeEnter: Guard.auth,
    components: {
      default: () => import('../views/ContractType/ContractTypeAdd'),
      menu: () => import('../views/Menu'),
    }
  },
  {
    path: '/contracttype/edit/:id?',
    name: 'contracttypeedit',
    beforeEnter: Guard.auth,
    components: {
      default: () => import('../views/ContractType/ContractTypeEdit'),
      menu: () => import('../views/Menu'),
    }
  },
]

export default contractTypeRoutes