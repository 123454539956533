import axios from 'axios'

export default (token) => {
  const instance = axios.create({
    baseURL: process.env.VUE_APP_API,
    headers: {
      Authorization: `Bearer ${JSON.parse(token.jwt)}`,
    }
  })
  return instance
}
