import { createRouter, createWebHashHistory } from "vue-router"
import homeRoutes from "./homeRoutes"
import userRoutes from "./userRoutes"
import customerRoutes from "./customerRoutes"
import contractRoutes from "./contractRoutes"
import receivableRoutes from "./receivableRoutes"
import workorderRoutes from "./workorderRoutes"
import onuRoutes from "./onuRoutes"
import boxRoutes from "./boxRoutes"
import companyRoutes from "./companyRoutes"
import contractTypeRoutes from "./contractTypeRoutes"
import bankAccountRoutes from "./bankAccountRoutes"
import mediaTypeRoutes from "./mediaTypeRoutes"
import scheduleMessageRoutes from "./scheduleMessageRoutes"
import reportRoutes from "./reportRoutes"
import cashHandRoutes from "./cashHandRoutes"
import chartAccountsRoutes from "./chartAccountsRoutes"
import popRoutes from "./popRoutes"
import nasRoutes from "./nasRoutes"
import termsPaymentRoutes from "./termsPaymentRoutes"
import servicePlanRoutes from "./servicePlanRoutes"
import salesPersonRoutes from "./salesPersonRoutes"
import oltRoutes from "./oltRoutes"
import printRoutes from "./printRoutes"
import prodServRoutes from "./prodServRoutes"
import onuTemplateRoutes from "./onuTemplateRoutes"
import sendMessageRoutes from "./sendMessageRoutes"
import payableRoutes from "./payableRoutes"
import vendorRoutes from "./vendorRoutes"

const routes = [
  ...homeRoutes,
  ...userRoutes,
  ...customerRoutes,
  ...contractRoutes,
  ...receivableRoutes,
  ...workorderRoutes,
  ...onuRoutes,
  ...boxRoutes,
  ...companyRoutes,
  ...contractTypeRoutes,
  ...bankAccountRoutes,
  ...mediaTypeRoutes,
  ...scheduleMessageRoutes,
  ...reportRoutes,
  ...cashHandRoutes,
  ...chartAccountsRoutes,
  ...popRoutes,
  ...nasRoutes,
  ...termsPaymentRoutes,
  ...servicePlanRoutes,
  ...salesPersonRoutes,
  ...oltRoutes,
  ...printRoutes,
  ...prodServRoutes,
  ...onuTemplateRoutes,
  ...sendMessageRoutes,
  ...payableRoutes,
  ...vendorRoutes,
]

const router = createRouter({
  history: createWebHashHistory(),
  routes,
})

export default router
