import Guard from '../services/middleware'

const homeRoutes = [
  {
    path: '/login',
    name: 'login',
    components: {
      default: () => import('../views/Login'),
      menu: () => import('../views/Banner'),
    }
  },
  {
    path: '/',
    name: 'home',
    beforeEnter: Guard.auth,
    components: {
      default: () => import('../views/Home'),
      menu: () => import('../views/Menu'),
    }
  },
  {
    path: '/:pathMatch(.*)*',
    name: '404',
    component: () => import('../views/404')
  },
]

export default homeRoutes