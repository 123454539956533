import Guard from "../services/middleware"

const servicePlanRoutes = [
  {
    path: "/serviceplan",
    name: "serviceplan",
    beforeEnter: Guard.auth,
    components: {
      default: () => import("../views/ServicePlan/ServicePlan"),
      menu: () => import("../views/Menu"),
    },
  },
  {
    path: "/serviceplan/add",
    name: "serviceplanadd",
    beforeEnter: Guard.auth,
    components: {
      default: () => import("../views/ServicePlan/ServicePlanAdd"),
      menu: () => import("../views/Menu"),
    },
  },
  {
    path: "/serviceplan/edit/:id?",
    name: "serviceplanedit",
    beforeEnter: Guard.auth,
    components: {
      default: () => import("../views/ServicePlan/ServicePlanEdit"),
      menu: () => import("../views/Menu"),
    },
  },
]

export default servicePlanRoutes
