<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col">
        <div>
          <router-view name="menu" />
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <!-- <div class="bg-light border rounded-3"> -->
        <div>
          <router-view />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {}
  },

  methods: {},
}
</script>

<style>
@import "./assets/css/all.css";
</style>
