import Guard from "../services/middleware"

const salesPersonRoutes = [
  {
    path: "/salesperson",
    name: "salesperson",
    beforeEnter: Guard.auth,
    components: {
      default: () => import("../views/SalesPerson/SalesPerson"),
      menu: () => import("../views/Menu"),
    },
  },
  {
    path: "/salesperson/add",
    name: "salespersonadd",
    beforeEnter: Guard.auth,
    components: {
      default: () => import("../views/SalesPerson/SalesPersonAdd"),
      menu: () => import("../views/Menu"),
    },
  },
  {
    path: "/salesperson/edit/:id?",
    name: "salespersonedit",
    beforeEnter: Guard.auth,
    components: {
      default: () => import("../views/SalesPerson/SalesPersonEdit"),
      menu: () => import("../views/Menu"),
    },
  },
]

export default salesPersonRoutes
