import Guard from "../services/middleware"

const vendorRoutes = [
  {
    path: "/vendor",
    name: "vendor",
    beforeEnter: Guard.auth,
    components: {
      default: () => import("../views/Vendor/Vendor"),
      menu: () => import("../views/Menu"),
    },
  },
  {
    path: "/vendor/add",
    name: "vendoradd",
    beforeEnter: Guard.auth,
    components: {
      default: () => import("../views/Vendor/VendorAdd"),
      menu: () => import("../views/Menu"),
    },
  },
  {
    path: "/vendor/edit/:id?",
    name: "vendoredit",
    beforeEnter: Guard.auth,
    components: {
      default: () => import("../views/Vendor/VendorEdit"),
      menu: () => import("../views/Menu"),
    },
  },
]

export default vendorRoutes
