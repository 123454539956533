import Guard from "../services/middleware"

const termsPaymentRoutes = [
  {
    path: "/termspayment",
    name: "termspayment",
    beforeEnter: Guard.auth,
    components: {
      default: () => import("../views/TermsPayment/TermsPayment"),
      menu: () => import("../views/Menu"),
    },
  },
  {
    path: "/termspayment/add",
    name: "termspaymentadd",
    beforeEnter: Guard.auth,
    components: {
      default: () => import("../views/TermsPayment/TermsPaymentAdd"),
      menu: () => import("../views/Menu"),
    },
  },
  {
    path: "/termspayment/edit/:id?",
    name: "termspaymentedit",
    beforeEnter: Guard.auth,
    components: {
      default: () => import("../views/TermsPayment/TermsPaymentEdit"),
      menu: () => import("../views/Menu"),
    },
  },
]

export default termsPaymentRoutes
