import Guard from "../services/middleware"

const oltRoutes = [
  {
    path: "/olt",
    name: "olt",
    beforeEnter: Guard.auth,
    components: {
      default: () => import("../views/Olt/Olt"),
      menu: () => import("../views/Menu"),
    },
  },
  {
    path: "/olt/add",
    name: "oltadd",
    beforeEnter: Guard.auth,
    components: {
      default: () => import("../views/Olt/OltAdd"),
      menu: () => import("../views/Menu"),
    },
  },
  {
    path: "/olt/edit/:id?",
    name: "oltedit",
    beforeEnter: Guard.auth,
    components: {
      default: () => import("../views/Olt/OltEdit"),
      menu: () => import("../views/Menu"),
    },
  },
  {
    path: "/olt/pon/add/:id?",
    name: "ponadd",
    beforeEnter: Guard.auth,
    components: {
      default: () => import("../views/Olt/Pon/PonAdd"),
      menu: () => import("../views/Menu"),
    },
  },
  {
    path: "/olt/pon/edit/:id?/:oltId?",
    name: "ponedit",
    beforeEnter: Guard.auth,
    components: {
      default: () => import("../views/Olt/Pon/PonEdit"),
      menu: () => import("../views/Menu"),
    },
  },
]

export default oltRoutes
