import Guard from '../services/middleware'

const mediaTypeRoutes = [
  {
    path: '/mediatype',
    name: 'mediatype',
    beforeEnter: Guard.auth,
    components: {
      default: () => import('../views/MediaType/MediaType'),
      menu: () => import('../views/Menu'),
    }
  },
  {
    path: '/mediatype/add',
    name: 'mediatypeadd',
    beforeEnter: Guard.auth,
    components: {
      default: () => import('../views/MediaType/MediaTypeAdd'),
      menu: () => import('../views/Menu'),
    }
  },
  {
    path: '/mediatype/edit/:id?',
    name: 'mediatypeedit',
    beforeEnter: Guard.auth,
    components: {
      default: () => import('../views/MediaType/MediaTypeEdit'),
      menu: () => import('../views/Menu'),
    }
  },
]

export default mediaTypeRoutes