import Guard from "../services/middleware"

const payableRoutes = [
  {
    path: "/payable",
    name: "payable",
    beforeEnter: Guard.auth,
    components: {
      default: () => import("../views/Payable/Payable"),
      menu: () => import("../views/Menu"),
    },
  },
  {
    path: "/payable/add",
    name: "payableadd",
    beforeEnter: Guard.auth,
    components: {
      default: () => import("../views/Payable/PayableAdd"),
      menu: () => import("../views/Menu"),
    },
  },
  {
    path: "/payable/addmany",
    name: "payableaddmany",
    beforeEnter: Guard.auth,
    components: {
      default: () => import("../views/Payable/PayableAddMany"),
      menu: () => import("../views/Menu"),
    },
  },
  {
    path: "/payable/edit/:id?",
    name: "payableedit",
    beforeEnter: Guard.auth,
    components: {
      default: () => import("../views/Payable/PayableEdit"),
      menu: () => import("../views/Menu"),
    },
  },
  {
    path: "/payable/settling/:id?",
    name: "payablesettling",
    beforeEnter: Guard.auth,
    components: {
      default: () => import("../views/Payable/PayableSettling"),
      menu: () => import("../views/Menu"),
    },
  },
]

export default payableRoutes
