import Guard from '../services/middleware'

const scheduleMessageRoutes = [
    {
        path: '/schedulemessage',
        name: 'schedulemessage',
        beforeEnter: Guard.auth,
        components: {
            default: () => import('../views/ScheduleMessage/ScheduleMessage'),
            menu: () => import('../views/Menu'),
        }
    },
    {
        path: '/schedulemessage/add',
        name: 'schedulemessageadd',
        beforeEnter: Guard.auth,
        components: {
            default: () => import('../views/ScheduleMessage/ScheduleMessageAdd'),
            menu: () => import('../views/Menu'),
        }
    },
    {
        path: '/schedulemessage/edit/:id?',
        name: 'schedulemessageedit',
        beforeEnter: Guard.auth,
        components: {
            default: () => import('../views/ScheduleMessage/ScheduleMessageEdit'),
            menu: () => import('../views/Menu'),
        }
    },
]

export default scheduleMessageRoutes