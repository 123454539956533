import Guard from "../services/middleware"

const sendMessageRoutes = [
  {
    path: "/sendmessage",
    name: "sendmessage",
    beforeEnter: Guard.auth,
    components: {
      default: () => import("../views/SendMessage/SendMessage"),
      menu: () => import("../views/Menu"),
    },
  },
]

export default sendMessageRoutes
