import Guard from "../services/middleware"

const chartAccountsRoutes = [
  {
    path: "/chartaccounts",
    name: "chartaccounts",
    beforeEnter: Guard.auth,
    components: {
      default: () => import("../views/ChartAccounts/ChartAccounts"),
      menu: () => import("../views/Menu"),
    },
  },
  {
    path: "/chartaccounts/add",
    name: "chartaccountsadd",
    beforeEnter: Guard.auth,
    components: {
      default: () => import("../views/ChartAccounts/ChartAccountsAdd"),
      menu: () => import("../views/Menu"),
    },
  },
  {
    path: "/chartaccounts/edit/:id?",
    name: "chartaccountsedit",
    beforeEnter: Guard.auth,
    components: {
      default: () => import("../views/ChartAccounts/ChartAccountsEdit"),
      menu: () => import("../views/Menu"),
    },
  },
]

export default chartAccountsRoutes
