import Guard from '../services/middleware'

const companyRoutes = [
  {
    path: '/company',
    name: 'company',
    beforeEnter: Guard.auth,
    components: {
      default: () => import('../views/Company/Company'),
      menu: () => import('../views/Menu'),
    }
  },
  {
    path: '/company/add',
    name: 'companyadd',
    beforeEnter: Guard.auth,
    components: {
      default: () => import('../views/Company/CompanyAdd'),
      menu: () => import('../views/Menu'),
    }
  },
  {
    path: '/company/edit/:id?',
    name: 'companyedit',
    beforeEnter: Guard.auth,
    components: {
      default: () => import('../views/Company/CompanyEdit'),
      menu: () => import('../views/Menu'),
    }
  },
]

export default companyRoutes