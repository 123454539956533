import Guard from "../services/middleware"

const onuRoutes = [
  {
    path: "/onu",
    name: "onu",
    beforeEnter: Guard.auth,
    components: {
      default: () => import("../views/Onu/Onu"),
      menu: () => import("../views/Menu"),
    },
  },
  {
    path: "/onu/discover",
    name: "onudiscover",
    beforeEnter: Guard.auth,
    components: {
      default: () => import("../views/Onu/OnuDiscover"),
      menu: () => import("../views/Menu"),
    },
  },
  {
    path: "/onu/add/:id?",
    name: "onuadd",
    beforeEnter: Guard.auth,
    components: {
      default: () => import("../views/Onu/OnuAdd"),
      menu: () => import("../views/Menu"),
    },
  },
]

export default onuRoutes
