import Guard from '../services/middleware'

const contractRoutes = [
  {
    path: '/customer/contract',
    name: 'contract',
    beforeEnter: Guard.auth,
    components: {
      default: () => import('../views/Contract/Contract'),
      menu: () => import('../views/Menu'),
    }
  },
  {
    path: '/customer/contract/add',
    name: 'contractadd',
    beforeEnter: Guard.auth,
    components: {
      default: () => import('../views/Contract/ContractAdd'),
      menu: () => import('../views/Menu'),
    }
  },
  {
    path: '/customer/contract/edit',
    name: 'contractedit',
    beforeEnter: Guard.auth,
    components: {
      default: () => import('../views/Contract/ContractEdit'),
      menu: () => import('../views/Menu'),
    }
  },
  {
    path: '/customer/contract/changeserviceplan',
    name: 'changeserviceplan',
    beforeEnter: Guard.auth,
    components: {
      default: () => import('../views/Contract/ChangeServicePlan'),
      menu: () => import('../views/Menu'),
    }
  },
  {
    path: '/customer/contract/changedue',
    name: 'changedue',
    beforeEnter: Guard.auth,
    components: {
      default: () => import('../views/Contract/ChangeDue'),
      menu: () => import('../views/Menu'),
    }
  },
]

export default contractRoutes