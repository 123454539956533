import Guard from '../services/middleware'

const userRoutes = [
  {
    path: '/user',
    name: 'user',
    beforeEnter: Guard.auth,
    components: {
      default: () => import('../views/User/User'),
      menu: () => import('../views/Menu'),
    }
  },
  {
    path: '/user/edit/:id?',
    name: 'useredit',
    beforeEnter: Guard.auth,
    components: {
      default: () => import('../views/User/UserEdit'),
      menu: () => import('../views/Menu'),
    }
  },
  {
    path: '/user/add',
    name: 'useradd',
    beforeEnter: Guard.auth,
    components: {
      default: () => import('../views/User/UserAdd'),
      menu: () => import('../views/Menu'),
    }
  },
  {
    path: '/usergroup',
    name: 'usergroup',
    beforeEnter: Guard.auth,
    components: {
      default: () => import('../views/UserGroup/UserGroup'),
      menu: () => import('../views/Menu'),
    }
  },
  {
    path: '/usergroup/add',
    name: 'usergroupadd',
    beforeEnter: Guard.auth,
    components: {
      default: () => import('../views/UserGroup/UserGroupAdd'),
      menu: () => import('../views/Menu'),
    }
  },
  {
    path: '/usergroup/edit/:id?',
    name: 'usergroupedit',
    beforeEnter: Guard.auth,
    components: {
      default: () => import('../views/UserGroup/UserGroupEdit'),
      menu: () => import('../views/Menu'),
    }
  },
  {
    path: '/permission',
    name: 'permission',
    beforeEnter: Guard.auth,
    components: {
      default: () => import('../views/Permission/Permission'),
      menu: () => import('../views/Menu'),
    }
  },
  {
    path: '/permission/edit/:id?',
    name: 'permissionedit',
    beforeEnter: Guard.auth,
    components: {
      default: () => import('../views/Permission/PermissionEdit'),
      menu: () => import('../views/Menu'),
    }
  },
  {
    path: '/permission/add',
    name: 'permissionadd',
    beforeEnter: Guard.auth,
    components: {
      default: () => import('../views/Permission/PermissionAdd'),
      menu: () => import('../views/Menu'),
    }
  },
]

export default userRoutes