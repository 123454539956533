/* eslint-disable */

import { createApp } from 'vue'
import App from './App.vue'
import { store } from './store/store'
import VueTheMask from 'vue-the-mask'
import MoneyInput from '@/components/MoneyInput.vue'

import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap/dist/js/bootstrap.bundle.min.js"

import 'bootstrap-icons/font/bootstrap-icons.css'

import PrimeVue from 'primevue/config'
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import InputText from 'primevue/inputtext'
import Toast from 'primevue/toast'
import ToastService from 'primevue/toastservice'
import Dialog from 'primevue/dialog'

import 'primevue/resources/themes/saga-blue/theme.css'

import 'primevue/resources/primevue.min.css'
import 'primeicons/primeicons.css'

import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'

library.add(fas, fab, far)

import { GoogleMap, Polyline, Marker, MarkerCluster } from "vue3-google-map"

import Router from './router/'

const app = createApp(App)

app.config.globalProperties.$filters = {
  moneyBrasil(value) {
    return `R$ ${parseFloat(value).toFixed(2)}`.replace('.', ',')
  },
  dateBrasil(value) {
    return value = new Date(value).toLocaleDateString('pt-BR', { timeZone: 'UTC' })
  }
}

app.use(Router)
app.use(VueTheMask)
app.use(store)
app.use(ToastService)

app.component('MoneyInput', MoneyInput)

app.use(PrimeVue)
app.component('DataTable', DataTable)
app.component('Column', Column)
app.component('InputText', InputText)
app.component('Dialog', Dialog)
app.component('Toast', Toast)
app.component('font-awesome-icon', FontAwesomeIcon)

app.component('GoogleMap', GoogleMap)
app.component('Polyline', Polyline)
app.component('Marker', Marker)
app.component('MarkerCluster', MarkerCluster)

app.mount('#app')
