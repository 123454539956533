import Guard from "../services/middleware"

const printRoutes = [
  {
    path: "/print",
    name: "print",
    beforeEnter: Guard.auth,
    components: {
      default: () => import("../views/Print/Print"),
      menu: () => import("../views/Menu"),
    },
  },
  {
    path: "/print/add",
    name: "printadd",
    beforeEnter: Guard.auth,
    components: {
      default: () => import("../views/Print/PrintAdd"),
      menu: () => import("../views/Menu"),
    },
  },
  {
    path: "/print/edit/:id?",
    name: "printedit",
    beforeEnter: Guard.auth,
    components: {
      default: () => import("../views/Print/PrintEdit"),
      menu: () => import("../views/Menu"),
    },
  },
]

export default printRoutes
