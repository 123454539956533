import Guard from '../services/middleware'

const workorderRoutes = [
  {
    path: '/workorder',
    name: 'workorder',
    beforeEnter: Guard.auth,
    components: {
      default: () => import('../views/WorkOrder/WorkOrder'),
      menu: () => import('../views/Menu'),
    }
  },
  {
    path: '/workorder/add',
    name: 'workorderadd',
    beforeEnter: Guard.auth,
    components: {
      default: () => import('../views/WorkOrder/WorkOrderAdd'),
      menu: () => import('../views/Menu'),
    }
  },
  {
    path: '/workorder/evaluation/:key?',
    name: 'evaluation',
    components: {
      default: () => import('../views/WorkOrder/Evaluation'),
    }
  },
]

export default workorderRoutes