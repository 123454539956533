import { createStore } from "vuex"
import createPersistedState from "vuex-persistedstate"

export const store = createStore({
  state() {
    return {
      CustomerId: null,
      ContractId: null,
      ReceivableId: null,
      userId: null,
      searchPayable: {},
    }
  },

  plugins: [createPersistedState()],

  mutations: {
    setCustomerId(state, payload) {
      state.CustomerId = payload
    },
    setCustomerName(state, payload) {
      state.CustomerName = payload
    },
    setContractId(state, payload) {
      state.ContractId = payload
    },
    setReceivableId(state, payload) {
      state.ReceivableId = payload
    },
    setUserId(state, payload) {
      state.userId = payload
    },
    setSearchPayable(state, payload) {
      state.searchPayable = payload
    },
  },

  getters: {
    getCustomerId(state) {
      return state.CustomerId
    },
    getCustomerName(state) {
      return state.CustomerName
    },
    getContractId(state) {
      return state.ContractId
    },
    getReceivableId(state) {
      return state.ReceivableId
    },
    getUserId(state) {
      return state.userId
    },
    getSearchPayable(state) {
      return state.searchPayable
    },
  },
})
