import Guard from "../services/middleware"

const popRoutes = [
  {
    path: "/pop",
    name: "pop",
    beforeEnter: Guard.auth,
    components: {
      default: () => import("../views/Pop/Pop"),
      menu: () => import("../views/Menu"),
    },
  },
  {
    path: "/pop/add",
    name: "popadd",
    beforeEnter: Guard.auth,
    components: {
      default: () => import("../views/Pop/PopAdd"),
      menu: () => import("../views/Menu"),
    },
  },
  {
    path: "/pop/edit/:id?",
    name: "popedit",
    beforeEnter: Guard.auth,
    components: {
      default: () => import("../views/Pop/PopEdit"),
      menu: () => import("../views/Menu"),
    },
  },
]

export default popRoutes
