import Guard from "../services/middleware"

const onuTemplateRoutes = [
  {
    path: "/onutemplate",
    name: "onutemplate",
    beforeEnter: Guard.auth,
    components: {
      default: () => import("../views/OnuTemplate/OnuTemplate"),
      menu: () => import("../views/Menu"),
    },
  },
  {
    path: "/onutemplate/add",
    name: "onutemplateadd",
    beforeEnter: Guard.auth,
    components: {
      default: () => import("../views/OnuTemplate/OnuTemplateAdd"),
      menu: () => import("../views/Menu"),
    },
  },
  {
    path: "/onutemplate/edit/:id?",
    name: "onutemplateedit",
    beforeEnter: Guard.auth,
    components: {
      default: () => import("../views/OnuTemplate/OnuTemplateEdit"),
      menu: () => import("../views/Menu"),
    },
  },
]

export default onuTemplateRoutes
