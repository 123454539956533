<!-- <template>
    <div>
        <input type="text" ref="moneyInput" class="form-control money" :value="formattedValue" :required="required"
            @input="updateValue($event.target.value)" />
    </div>
</template>

<script>
export default {
    name: "MoneyInput",
    props: {
        value: {
            type: String,
        },
        required: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            formattedValue: this.formatValue(this.value),
        };
    },
    mounted() {
        this.$refs.moneyInput.addEventListener("input", this.handleInput);
    },
    beforeUnmount() {
        this.$refs.moneyInput.removeEventListener("input", this.handleInput);
    },
    watch: {
        value(newValue) {
            this.formattedValue = this.formatValue(newValue);
        },
    },
    methods: {
        handleInput() {
            clearTimeout(this.timeout);
            this.timeout = setTimeout(() => {
                this.updateValue(this.$refs.moneyInput.value);
            }, 1000);
        },
        updateValue(newValue) {
            const unformattedValue = newValue.replace(/\D/g, "") / 100;
            this.$emit("update:value", unformattedValue.toFixed(2));
        },
        formatValue(value) {
            const unformattedValue = parseFloat(value);
            if (!isNaN(unformattedValue)) {
                return unformattedValue.toLocaleString("pt-BR", {
                    style: "currency",
                    currency: "BRL",
                });
            }
            return "";
        },
    },
};
</script> -->

<!-- <template>
    <div>
        <input type="text" ref="moneyInput" class="form-control money" :value="formattedValue" :required="required"
            :disabled="disabled" @input="updateValue($event.target.value)" />
    </div>
</template>

<script>
export default {
    name: "MoneyInput",
    props: {
        value: {
            type: String,
        },
        required: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            formattedValue: this.formatValue(this.value),
        };
    },
    mounted() {
        this.$refs.moneyInput.addEventListener("input", this.handleInput);
    },
    beforeUnmount() {
        this.$refs.moneyInput.removeEventListener("input", this.handleInput);
    },
    watch: {
        value(newValue) {
            this.formattedValue = this.formatValue(newValue);
        },
    },
    methods: {
        handleInput() {
            clearTimeout(this.timeout);
            this.timeout = setTimeout(() => {
                this.updateValue(this.$refs.moneyInput.value);
            }, 1000);
        },
        updateValue(newValue) {
            const unformattedValue = newValue.replace(/\D/g, "") / 100;
            this.$emit("update:value", unformattedValue.toFixed(2));
        },
        formatValue(value) {
            const unformattedValue = parseFloat(value);
            if (!isNaN(unformattedValue)) {
                return unformattedValue.toLocaleString("pt-BR", {
                    style: "currency",
                    currency: "BRL",
                });
            }
            return "";
        },
    },
};
</script> -->
<template>
    <div>
        <input ref="moneyInput" type="text" class="form-control money" :value="formattedValue" :required="required"
            :disabled="disabled" @input="updateValue($event.target.value)" />
    </div>
</template>

<script>
export default {
    name: "MoneyInput",
    props: {
        value: {
            type: String,
        },
        required: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            formattedValue: this.formatValue(this.value),
        };
    },
    mounted() {
        this.$refs.moneyInput.addEventListener("input", this.handleInput);
    },
    beforeUnmount() {
        this.$refs.moneyInput.removeEventListener("input", this.handleInput);
    },
    watch: {
        value(newValue) {
            this.formattedValue = this.formatValue(newValue);
        },
    },
    methods: {
        handleInput() {
            clearTimeout(this.timeout);
            this.timeout = setTimeout(() => {
                this.updateValue(this.$refs.moneyInput.value);
            }, 1000);
        },
        updateValue(newValue) {
            const unformattedValue = newValue.replace(/\D/g, "") / 100;
            this.$emit("update:value", unformattedValue.toFixed(2));
        },
        formatValue(value) {
            const unformattedValue = parseFloat(value);
            if (!isNaN(unformattedValue)) {
                return unformattedValue.toLocaleString("pt-BR", {
                    style: "currency",
                    currency: "BRL",
                });
            }
            return "";
        },
        focusInput() {
            this.$refs.moneyInput.focus();
        },
    },
};
</script>